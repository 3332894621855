import LoaderDark from "./LoaderDark/LoaderDark";
import React from "react";

export default function LoaderWithText({ text }: any) {
  return (
    <div className="flex items-center justify-center gap-2 mt-20">
      <LoaderDark />
      <span>Loading {text}...</span>
    </div>
  );
}
