import LeftArrowIcon from "../../../../assets/icons/LeftArrowIcon";
import DefaultImg from "../../../../assets/img/Rectangle 12667@2x.png";
import Img01 from "../../../../assets/img/img_01.jpg";
import useSound from "../../../../hooks/useSound";
import Bottom from "../../../Layouts/Bottom";
import Sidebar from "../../../Layouts/Sidebar";
import "./SoundTrack.css";
import quickplay from "quickplay";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function SoundTrack() {
  const { soundId } = useParams();
  const navigate = useNavigate();

  const { sound, videos: videosBySound }: any = useSound(soundId as string);

  return (
    <>
      <div className="full-sec">
        <div className="left-sec">
          <Sidebar />
        </div>
        <div className="right-sec">
          <div className="mainpage">
            <div className="back-btn">
              <button className="back-btn-a" onClick={() => navigate(-1)}>
                <LeftArrowIcon />
                <div>Back</div>
              </button>
            </div>
            <div className="mainpage-head">
              <div className="sound-image">
                <img src={sound?.thumbnail} alt={`${sound?.name}-thumbnail`} />
              </div>
              <h2 className="sound-title">{sound.name}</h2>
              <p className="sound-artist">{sound.artist}</p>
              <audio src={sound.soundUrl} controls autoPlay></audio>
            </div>
            <div className="mainpage-body">
              {videosBySound.length > 0 ? (
                <div className="sound-grid">
                  {videosBySound.map((video: any) => (
                    <div className="sound-grid-item">
                      <img src={Img01} alt="" />
                    </div>
                  ))}
                </div>
              ) : (
                <div>No videos found for this sound</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
}

export default SoundTrack;
