import "./App.css";
import ForgotPasswordForm from "./components/Auth/ForgotPasswordForm";
import DeepLink from "./components/DeepLink";
import LoginModal from "./components/Login-UI/LoginModal";
import STCLogin from "./components/STCLogin/STCLogin";
import VerticalPlayer from "./components/VerticalPlayer";
import ToastNotification from "./components/VerticalPlayer/components/Notification/Notification";
import SoundTrack from "./components/VerticalPlayer/components/SoundTrack";
import { useLoginModalContext } from "./contexts/LoginModal";
import { useNotification } from "./contexts/Notification";
import ActivityTimeline from "./pages/ActivityTimeline";
import ContentDetailPage from "./pages/ContentDetail";
import CreateContent from "./pages/CreatePost";
import CreateContentStepTwo from "./pages/CreatePostStepTwp";
import CreateTextContent from "./pages/CreateText";
import Discover from "./pages/Discover";
import DiscoverContent from "./pages/DiscoverContent";
import HashtagDetail from "./pages/HashtagDetail";
import MyProfile from "./pages/MyProfile";
import Notification from "./pages/Notification";
import Profile from "./pages/Profile";
import Signup from "./pages/Signup";
import SearchFilter from "./pages/searchFilter";
import i18n from "./translation/translation";
import quickplay from "quickplay";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  i18n.init();
  const storedValue = localStorage.getItem("defaultLanguage");
  let quickPlaySDKInitilize = new quickplay.QuickPlaySDKInitilize();
  // quickPlaySDKInitilize.initialize({
  //   apiKey: "5bdd28f0-a425-4884-be50-9bf5fa35e319",
  //   secretKey: "33728ccd-9dec-4bfe-8a66-0c489a1fc8f2",
  // });
  //   quickPlaySDKInitilize.initialize({
  //     apiKey: "190b48a3-d684-46b7-93fd-a0f46433b8df",
  //     secretKey: "dad52101-ecce-4dc5-b014-aa656a8fc1a4",
  //   });
  quickPlaySDKInitilize.initialize({
    apiKey: "fd83b27e-3efd-404b-810a-fb90ca13e51a",
    secretKey: "d9167416-8165-47bc-b55d-87d3432a1e65",
  });

  const { showLoginModal, setShowLoginModal } = useLoginModalContext();
  const { notification, closeNotification } = useNotification();

  return (
    <div
      className="App"
      id="direction"
      dir={storedValue === "ar" ? "rtl" : "ltr"}
    >
      <Router>
        <Routes>
          <Route path="" element={<VerticalPlayer />} />
          <Route path="/sign-up" element={<Signup />} />
          {/* <Route path="/sign-in" element={<Login />} /> */}
          <Route path="/discover" element={<Discover />} />
          <Route path="/discover/content" element={<DiscoverContent />} />
          <Route path="/activity-timeline" element={<ActivityTimeline />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/profile/:userId" element={<Profile />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/create-post" element={<CreateContent />} />
          <Route
            path="/create-post-page-02"
            element={<CreateContentStepTwo />}
          />
          <Route path="/content/:contentId" element={<ContentDetailPage />} />
          <Route path="/hashtag/:hashtagName" element={<HashtagDetail />} />
          <Route path="/create-text" element={<CreateTextContent />} />
          <Route path="/search-filter" element={<SearchFilter />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/vertical-player" element={<VerticalPlayer />} />
          <Route path="/sound-track/:soundId" element={<SoundTrack />} />
          <Route path="/feed" element={<DeepLink />} />
          <Route path="/sign-in" element={<STCLogin />} />
        </Routes>
        <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
        />
        {notification.show && (
          <ToastNotification
            show={notification.show}
            title={notification.title}
            subTitle={notification.subTitle}
            type={notification.type}
            onClose={closeNotification}
          />
        )}
      </Router>
    </div>
  );
}

export default App;
