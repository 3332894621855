import React from "react";

function Unmute() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.189"
      height="20.874"
      viewBox="0 0 27.189 20.874"
    >
      <path
        id="Path_16172"
        data-name="Path 16172"
        d="M2.236,18.936H7.042l6.543,5.354a.618.618,0,0,0,1.009-.478V4.173a.618.618,0,0,0-1.009-.478L7.042,9.048H2.236A1.236,1.236,0,0,0,1,10.284V17.7A1.236,1.236,0,0,0,2.236,18.936Zm25.953-4.944A13.562,13.562,0,0,1,23.57,24.2l-1.752-1.752a11.124,11.124,0,0,0,0-16.918L23.57,3.781A13.562,13.562,0,0,1,28.189,13.992Zm-6.179,0a7.4,7.4,0,0,0-2.826-5.825L17.418,9.933a4.945,4.945,0,0,1,0,8.118l1.766,1.766A7.4,7.4,0,0,0,22.01,13.992Z"
        transform="translate(-1 -3.555)"
        fill="#fafbfd"
      />
    </svg>
  );
}

export default Unmute;
