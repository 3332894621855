import { useEffect, useState } from "react";
import quickplay from "quickplay";

const soundModule = new quickplay.QuickPlaySoundModule();

export default function useSound(soundId: string) {
  const [sound, setSound] = useState<any>({});
  const [videosListBySound, setVideosListBySound] = useState<any>([]);

  useEffect(() => {
    const fetchSoundDetails = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("userDetails")!)?.token;
        const apiUrl = `<>`;
        const response = await fetch(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const { statusCode, result: soundData } = await response.json();
        if (statusCode === 2001) {
          setSound(soundData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchVideoListBySound = async () => {
      try {
        const {
          data: { statusCode, result: videosList },
        } = await soundModule.fetchVideoListBySoundId(soundId);

        if (statusCode === 2004) {
          setVideosListBySound(videosList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // fetchVideoListBySound();

    // fetchSoundDetails();
  }, [soundId]);

  return { sound: sound, videos: videosListBySound };
}
