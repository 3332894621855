import React from "react";

function UnLikeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_21751"
      data-name="Group 21751"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="Path_16063"
        data-name="Path 16063"
        d="M0,0H32V32H0Z"
        fill="none"
      />
      <path
        className="like_unlinke_icon"
        id="Path_16064"
        data-name="Path 16064"
        d="M21.333,3c4.051,0,7.333,3.333,7.333,8,0,9.333-10,14.667-13.333,16.667C12,25.667,2,20.333,2,11,2,6.333,5.333,3,9.333,3a8.822,8.822,0,0,1,6,2.667A8.822,8.822,0,0,1,21.333,3ZM16.579,23.805a35.938,35.938,0,0,0,3.227-2.268C23.78,18.377,26,14.924,26,11c0-3.147-2.049-5.333-4.667-5.333a6.144,6.144,0,0,0-4.115,1.885L15.333,9.437,13.448,7.552A6.144,6.144,0,0,0,9.333,5.667C6.747,5.667,4.667,7.875,4.667,11c0,3.925,2.221,7.377,6.193,10.537A35.878,35.878,0,0,0,14.088,23.8c.4.252.793.493,1.245.763.452-.269.847-.511,1.245-.761Z"
        transform="translate(0.667 1)"
        fill="#ffffff"  
      />
    </svg>
  );
}

export default UnLikeIcon;
